import React, {useState, useEffect, useRef} from 'react';
import { Fade } from 'react-slideshow-image';




const Slideshow = ({images = [], staggerTransition, noAutoplay}) => {
  let carouselRef = React.createRef();

  useEffect(() => {
    if (noAutoplay) return
    setTimeout(() => {
      setInterval(() => {
        carouselRef.current && carouselRef.current.goNext && carouselRef.current.goNext()
      }, 4000);
    }, staggerTransition ? 2000 : 0)

  }, [])


  const fadeProperties = {
    duration: 4000,
    transitionDuration: 500,
    infinite: true,
    indicators: images.length > 1,
    arrows: false,
    autoplay: false
  }


  return (
    <div className="slide-container">
      <Fade ref={carouselRef} {...fadeProperties} >
        {images.map(img => {
          const imageStyle = !noAutoplay ? {backgroundImage: `url(${img})`, width: "100%", minHeight: 320, backgroundSize: "cover", backgroundPosition: "50% 50%"} : {backgroundImage: `url(${img})`, width: "100%", minHeight: 180, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "50% 0%", marginTop: 10}
          return <div className="each-fade">
            <div className="image-container" style={imageStyle}>
            </div>
          </div>
        })}
      </Fade>
    </div>
  )
}

export default Slideshow
